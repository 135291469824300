import React from 'react'
import * as styles from './custom.module.css'
const PagesHeader = () => {
  return (
    <header className={`pages-header circle-bg valign ${styles}`}>
      <div className="container">

        <h1 className={`color-font fw-700 wow color-font${styles.title} `} >
          About US
        </h1>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="cont mt-100 mb-50 text-center">
              {/*   <h1 className={`color-font fw-700 ${styles.title}`}>
                About US
              </h1> */}
            </div>
          </div>
          {/* <div className="col-lg-10">
            <div className="img">
              <img src="/img/slid/about.jpg" alt="" />
            </div>
          </div> */}
        </div>
      </div>
      <div className="half sub-bg">
        <div className="circle-color">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
    </header>
  );
};

export default PagesHeader;