import React from "react";
import cardMouseEffect from "common/cardMouseEffect";
import { thumparallaxDown } from "common/thumparallax";
import * as styles from "./custom.module.css"
import aboutData from "data/aboutpage/custom-about.json"
import { CheckCircle, Dot, Sparkle } from 'lucide-react'
import footData from "data/aboutpage/about-foot.json"

const MinimalArea = () => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);


  return (
    <>
      <section className={`min-area ${styles.container}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="img">
                <img
                  className="thumparallax-down"
                  src="/img/min-area.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 valign">
              <div className="content pt-0">
                <h4 className="wow color-font">Our Competences</h4>

                {aboutData.map((e) => {
                  return <div key={e.id}>

                    <h5>{e.title}</h5>
                    {/* <ul>
                    {e.content.map((c, i) => {
                      return <li key={i + `content`} className={`wow txt ${styles.li}`} data-splitting>
                        <span >{c.heading}:</span> <br /> {c.description}
                      </li>
                    })}

                  </ul> */}


                  </div>
                })}

                {/* --- space ---- */}

                <ul className="feat">
                  {footData.map((e) => {
                    if (e.id <= 2) {
                      return (
                        <li key={`foot` + e.id} className={`wow fadeInUp ${styles.textToBlock}`} data-wow-delay=".2s">
                          <h6 style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Sparkle />
                            </span>

                            {e.title}
                          </h6>
                          {e.content.map((c, index) => (

                            <div key={`content` + index}>

                              <li style={{ display: 'flex', alignItems: 'start' }}>
                                <span >
                                  <Dot />
                                </span>

                                {c.description}
                              </li>

                            </div>
                          ))}
                        </li>
                      );
                    }

                  })}


                </ul>
              </div>
            </div>
          </div>


        </div>
      </section>

      <div className={styles.whyUs}>
        <div className={styles.container}>
          <h2 className={styles.title}>Why Us?</h2>
          <div className={styles.featureList}>
            {footData[2].content.map((e, i) => (
              <Feature key={`fet ${i}`} text={e.description} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MinimalArea;



function Feature({ text }) {
  return (
    <div className={styles.feature}>
      <CheckCircle className={styles.icon} />
      <p className={styles.featureText}>{text}</p>
    </div>
  )
}
